/**
 * 随机生成指定长度的字符串
 * @param length
 * @returns {string}
 */
export function randomString (length = 32) {
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const maxPos = chars.length
  let _string = ''
  for (let i = 0; i < length; i++) {
    _string += chars.charAt(Math.floor(Math.random() * maxPos))
  }
  return _string
}

function initDateNum (num) {
  return num < 10 ? '0' + num : num
}

/**
 * 时间戳 转换
 * @param val(规定转换为何种格式)
 * @param time(时间戳)
 * @returns {string}
 */
export function formatterDate (val, time) {
  const date = time ? new Date(time) : new Date()
  const year = date.getFullYear()
  const month = initDateNum(date.getMonth() + 1)
  const day = initDateNum(date.getDate())
  const hours = initDateNum(date.getHours())
  const minutes = initDateNum(date.getMinutes())
  const second = initDateNum(date.getSeconds())
  if (val === 'year') {
    return year
  } else if (val === 'month') {
    return month
  } else if (val === 'day') {
    return day
  } else if (val === 'chs') {
    return (
      year + '年' + month + '月' + day + '日' + hours + '时' + minutes + '分'
    )
  } else if (val === 'yy-mm-dd') {
    return year + '-' + month + '-' + day
  } else if (val === 'yy-mm-dd hh-mm') {
    return `${year}-${month}-${day} ${hours}:${minutes}`
  } else if (val === 'yy-mm-dd hh-mm-ss') {
    return `${year}-${month}-${day} ${hours}:${minutes}:${second}`
  }
}
