<template>
  <div class="supplier-add" ref="supplierAdd">
    <div class="st-wrapper">
      <div class="card">
        <div
          class="field"
          @click="supplierVisible = true"
          required
          data-label="supplier"
        >
          <div class="name">
            <s>*</s>
            <span>供应商</span>
          </div>
          <div class="right">
            <input
              v-model="pageData.supplier"
              type="text"
              placeholder="请选择"
              readonly
            />
            <img src="../../../../assets/images/icon/arrow-r-m-03.png" alt="" />
          </div>
        </div>
        <div
          class="field"
          @click="dateVisible = true"
          required
          data-label="date"
        >
          <div class="name">
            <s>*</s>
            <span>进场时间</span>
          </div>
          <div class="right">
            <input
              v-model="pageData.date"
              type="text"
              placeholder="请选择"
              readonly
            />
            <img src="../../../../assets/images/icon/arrow-r-m-03.png" alt="" />
          </div>
        </div>
        <div
          class="field"
          @click="unitVisible = true"
          required
          data-label="unit"
        >
          <div class="name">
            <s>*</s>
            <span>计量单位</span>
          </div>
          <div class="right">
            <input
              v-model="pageData.unit"
              type="text"
              placeholder="请选择"
              readonly
            />
            <img src="../../../../assets/images/icon/arrow-r-m-03.png" alt="" />
          </div>
        </div>
      </div>
      <div class="card" v-for="(item, index) in pageData.data" :key="index">
        <div class="field" @click="selectFood(index)">
          <div class="name">
            <s>*</s>
            <span>食材名称{{ index + 1 }}</span>
          </div>
          <div class="right">
            <input
              v-model="item.name"
              type="text"
              placeholder="请选择"
              readonly
            />
            <img src="../../../../assets/images/icon/arrow-r-m-03.png" alt="" />
          </div>
        </div>
        <div class="field">
          <div class="food-info">
            <div class="num input-wrap unit">
              <input
                v-model.number="item.quantity"
                type="number"
                placeholder="数量"
                @input="checkout(index, 'quantity')"
              />
              <span>{{ item.unit }}</span>
            </div>
            <div class="price input-wrap unit">
              <input
                v-model.number="item.price"
                type="number"
                placeholder="单价"
                @input="checkout(index, 'price')"
              />
              <span>元</span>
            </div>
            <div class="total input-wrap unit">
              <input
                :value="list[index].amount"
                type="text"
                placeholder="总价"
                readonly
              />
              <span>元</span>
            </div>
          </div>
        </div>
      </div>
      <div class="add-button" @click="onAddFood">
        <span>新增信息</span>
      </div>
    </div>
    <footer-button text="确定" @confirm="confirm" />
    <!-- 供应商 弹出层 -->
    <van-popup v-model="supplierVisible" round position="bottom">
      <van-picker
        show-toolbar
        value-key="name"
        :columns="supplierList"
        @cancel="supplierVisible = false"
        @confirm="onSupplierConfirm"
      />
    </van-popup>
    <!-- 时间选择 弹出层 -->
    <van-popup v-model="dateVisible" round position="bottom">
      <van-datetime-picker
        v-model="currentDate"
        type="date"
        title="选择年月日"
        :max-date="maxDate"
        @cancel="dateVisible = false"
        @confirm="onDateConfirm"
      />
    </van-popup>
    <!-- 计量单位 弹出层 -->
    <van-popup v-model="unitVisible" round position="bottom">
      <van-picker
        show-toolbar
        value-key="alias"
        :columns="unitList"
        @cancel="unitVisible = false"
        @confirm="onUnitConfirm"
      />
    </van-popup>
    <!-- 食材列表 弹出层 -->
    <van-popup v-model="foodVisible" round position="bottom">
      <van-picker
        show-toolbar
        value-key="name"
        :columns="foodList"
        @cancel="foodVisible = false"
        @confirm="onFoodConfirm"
      />
    </van-popup>
  </div>
</template>

<script>
import { Popup, Picker, DatetimePicker, Toast } from 'vant'
import FooterButton from '../../../../components/footer/button/Index'
import { formatterDate } from '../../../../utils'
export default {
  components: {
    [Popup.name]: Popup,
    [Picker.name]: Picker,
    [DatetimePicker.name]: DatetimePicker,
    [Toast.name]: Toast,
    FooterButton
  },
  data () {
    return {
      // 供应商 弹出层
      supplierVisible: false,
      supplierList: [],
      // 选择时间 弹出层
      currentDate: new Date(),
      dateVisible: false,
      maxDate: new Date(),
      // 计量单位 弹出层
      unitVisible: false,
      unitList: ['斤', '公斤', '克', '千克'],
      // 食材列表 弹出层
      foodVisible: false,
      foodIndex: null,
      foodList: [],
      sendData: { // 发送数据
        bid: null,
        unit: '',
        data: [
          {
            name: '',
            productId: null,
            supplyId: null,
            unit: '',
            price: '',
            quantity: ''
          }
        ],
        supplier: '',
        supplyId: null,
        day: null
      },
      editSendData: {
        bid: null,
        bno: null,
        supplyId: null,
        supplier: '',
        catalog: '',
        day: null
      },
      pageData: {
        bid: null,
        unit: '',
        data: [
          {
            name: '',
            productId: null,
            supplyId: null,
            unit: '',
            price: '',
            quantity: ''
          }
        ],
        supplier: '',
        supplyId: null,
        date: ''
      }
    }
  },
  created () {
    const { bid, bno, supplyId } = this.$route.query
    this.pageData.bid = bid
    if (bno) {
      this.pageData.supplyId = supplyId
      this.editSendData.bno = bno
      this.getRecordDetail(bid, bno)
      this.getFoodList(supplyId)
    }
    this.getSupplierList()
    this.getUnitList()
  },
  computed: {
    list () {
      const list = this.pageData.data.map(item => {
        if (item.price !== '' && item.quantity !== '') {
          item.amount = parseFloat((item.price * item.quantity).toFixed(2))
        } else {
          item.amount = ''
        }
        return item
      })
      return list
    }
  },
  methods: {
    // 强制两位数
    checkout (index, attr) {
      this.pageData.data[index][attr] = parseFloat(this.pageData.data[index][attr].toFixed(2))
    },
    // 查询入库记录详情
    getRecordDetail (bid, bno) {
      this.$api.supplier.recordDetail({ bid, bno }).then(res => {
        if (res.data.success) {
          const data = res.data.data
          console.log('获取入库详情：', data)
          this.pageData.supplier = data.supplier
          const date = data.sec.toString()
          this.pageData.date = date.substring(0, 4) + '-' + date.substring(4, 6) + '-' + date.substring(6, 8)
          this.currentDate = new Date(this.pageData.date)
          this.editSendData._id = data._id
          this.editSendData.catalog = data.catalog
          if (data.items) {
            this.pageData.unit = data.items[0].unit
            this.pageData.data = data.items
          }
        }
      })
    },
    // 获取 供应商列表
    getSupplierList () {
      this.$api.supplier.list({
        bid: this.$route.query.bid
      }).then(res => {
        // console.log('供应商列表:', res)
        if (res.data.success) {
          this.supplierList = res.data.result
        }
      })
    },
    // 获取 单位列表
    getUnitList () {
      this.$api.supplier.unitList().then(res => {
        // console.log('单位列表:', res)
        if (res.data.success) {
          this.unitList = res.data.result
        }
      })
    },
    // 获取 食材列表
    getFoodList (supplyId) {
      this.$api.supplier.foodList({ supplyId: supplyId }).then(res => {
        // console.log('食材列表:', res)
        if (res.data.success) {
          this.foodList = res.data.data || []
        }
      })
    },
    // 供应商 选择 确认
    onSupplierConfirm (item) {
      // console.log(item)
      this.pageData.supplyId = item._id
      this.pageData.supplier = item.name
      this.supplierVisible = false
      this.pageData.data = this.pageData.data.map(item => {
        item.supplyId = item._id
        return item
      })
      this.getFoodList(item._id)
    },
    // 进场时间 选择 确认
    onDateConfirm (item) {
      this.pageData.date = formatterDate('yy-mm-dd', item.getTime())
      this.dateVisible = false
    },
    // 计量单位 选择 确认
    onUnitConfirm (unitItem) {
      this.pageData.unit = unitItem.alias
      this.pageData.data = this.pageData.data.map(item => {
        item.unit = unitItem.alias
        return item
      })
      this.unitVisible = false
    },
    selectFood (index) {
      if (this.pageData.supplyId === null) {
        Toast('请先选择供应商')
        return false
      }
      if (this.foodList.length === 0 || this.foodList.length === null) {
        Toast('该供应商下没有可选食材')
        return false
      }
      this.foodIndex = index
      this.foodVisible = true
    },
    // 食材列表选择 确认
    onFoodConfirm (item) {
      // console.log(item)
      this.pageData.data[this.foodIndex].name = item.name
      this.pageData.data[this.foodIndex].productId = item._id
      this.pageData.data[this.foodIndex].imageUrl = item.imageUrl
      this.foodVisible = false
    },
    // 新增信息
    onAddFood () {
      if (this.pageData.supplyId === null) {
        Toast('请先选择供应商')
        return false
      }
      this.pageData.data.push({
        name: '',
        productId: null,
        supplyId: this.pageData.supplyId,
        unit: this.pageData.unit,
        price: '',
        quantity: ''
      })
    },
    // 提交
    confirm () {
      // console.log(this.pageData)
      const fields = this.$refs.supplierAdd.querySelectorAll('.field')
      for (let i = 0; i < fields.length; i++) {
        const item = fields[i]
        if (item.getAttribute('required')) {
          if (this.pageData[item.getAttribute('data-label')] === '') {
            Toast({ message: '请填写' + item.querySelector('span').innerHTML })
            return false
          }
        }
      }

      if (this.pageData.data[0].name === '') {
        Toast({ message: '请至少选择一项食材' })
        return false
      }
      if (this.pageData.data[0].price === '') {
        Toast({ message: '请填写食材的数量' })
        return false
      }
      for (let i = 0; i < this.pageData.data.length; i++) {
        if (this.pageData.data[i].name === '' || this.pageData.data[i].price === '' || this.pageData.data[i].quantity === '') {
          this.pageData.data.splice(i, 1)
        }
      }

      const { bid, date, unit, supplyId, supplier, data } = this.pageData
      this.editSendData.bid = this.sendData.bid = bid
      this.editSendData.supplyId = this.sendData.supplyId = supplyId
      this.editSendData.items = this.sendData.data = data
      this.editSendData.day = parseInt(date.replace(/-/g, ''))
      this.sendData.date = date
      this.sendData.unit = unit
      this.editSendData.supplier = supplier
      if (this.$route.query.bno) {
        this.$api.supplier.editRecord(this.editSendData).then(res => {
          if (res.data.success) {
            this.$router.replace({
              name: 'EntryRecord',
              query: {
                bid: this.$route.query.bid
              }
            })
          }
        })
      } else {
        this.$api.supplier.addRecord(this.sendData).then(res => {
          if (res.data.success) {
            this.$router.replace({
              name: 'EntryRecord',
              query: {
                bid: this.$route.query.bid
              }
            })
          }
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
